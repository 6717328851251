#modal-play-bonus-game {
  position: relative;
  z-index: 20;

  .modal__content {
    margin: auto;
    max-width: 327px;
    text-align: center;
    background-color: transparent;
    position: relative;
  }
}

.play-bonus-game-container {
  display: grid;
  grid-template-rows: 237px 1fr;
  justify-items: center;
  background-color: #FFFFFF;
  border-radius: 16px;

  .icon-diamond {
    position: absolute;
    z-index: 2;
    left: -3%;
    top: -8%;
  }

  .icon-coin {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: -26%;
    margin: auto;
  }

  .icon-heart {
    position: absolute;
    z-index: 2;
    right: -3%;
    top: -18%;
  }

  .bonus-game-header {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 1em 1em 0px 0px;
    background: linear-gradient(180deg, #A7A3FE 0%, #6B66CB 100%);
    clip-path: ellipse(100% 100% at top center);

    .image-wrapper {
      position: relative;
    }

    .header-image {
      display: block;
      max-width: 100%;
      height: 100%;
      margin-top: -1px;
    }
  }

  .bonus-game-content {
    display: flex;
    gap: 1em;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    width: 100%;
    text-align: center;

    .bonus-game-subtitle {
      font-weight: 900;
      font-size: 28px;
      line-height: 36px;
      color: #A7A3FE;
    }

    .bonus-game-title {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 900;
      font-size: 44px;
      line-height: 44px;
    }

    .bonus-game-text {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      font-size: 1em;
      line-height: 1.1em;
    }

    .share-area {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      row-gap: 1em;
    }
  }
}
