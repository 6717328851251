@import "../../mixins";

.error-page-content {
  padding: 24px;
  text-align: center;

  .btn-wrapper {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 24px;
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px)  {
  .error-page-content {
    padding: 16px;
  }
}
