.accordion-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.faq-content {
  padding-left: 24px;
  padding-right: 24px;
}

.accordion {
    list-style: none;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .faq-content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
