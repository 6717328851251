#onboarding {
  width: 100%;
  max-width: 38em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .onboarding-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 34px;
    padding-right: 34px;
  }

  .swiper-wrapper {
    z-index: 11;
  }

  .slide-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .image-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2em;
      width: 100%;
    }

    .image-background {
      border-radius: 40%;
      background: rgba(0, 0, 0, 0.16);
      aspect-ratio: 1 /1;
      position: relative;
      width: 295px;
      max-width: 100%;
    }

    img {
      position: absolute;
      width: 120%;
      height: auto;
      max-width: 370px;
    }

    .text-container {
      width: 93%;
      margin-top: 48px;

      h3 {
        margin: 0;
        font-size: 18px;
        line-height: 1.35;
      }
    }
  }

  .swiper {
    padding: 0 10%;
    margin: 0 -9%;

    .swiper-pagination-bullets {
      position: absolute;
      top: 93vw;
      bottom: auto;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 28px;
  }

  .service-banner {
    margin-top: auto;
  }
}

@media (min-width: 367px) {
  #onboarding {
    .swiper {
      .swiper-pagination-bullets {
        top: 345px;
      }
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 750px) {
  #onboarding {
    .onboarding-container {
      padding-left: 24px;
      padding-right: 24px;
    }

    .swiper {
      padding: 0;
      margin: 0;

      .swiper-pagination-bullets {
        top: 260px;
      }
    }

    .slide-container {
      .image-background {
        width: 206px;
      }

      img {
        width: 100%;
        max-width: 270px;
      }

      .text-container {
        width: 100%;

        h3 {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .buttons {
      padding-top: 21px;
    }
  }
}
