@import "../../../mixins";

#opponent {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: auto;

  .opponent-content {
    max-width: 500px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    .header-transparent {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .searching-content {
    padding-top: 34px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .searching-title {
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      line-height: 32px;
      text-align: center;
      color: #FFFFFF;
      @include shadow-text;
    }

    .countdown {
      margin-bottom: 34px;

      .starting-title {
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #FFFFFF;
        @include shadow-text;
      }

      .number {
        font-style: normal;
        font-weight: 900;
        font-size: 60px;
        line-height: 50px;
        margin-top: 8px;

        text-align: center;
        color: #FFFFFF;
        @include shadow-text;
      }
    }

    .war-background {
      background: rgba(0, 0, 0, 0.08);
      border-radius: 100px;
      height: 132px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: -1;
      margin-top: 34px;
      margin-bottom: 34px;
      position: relative;

      .war-thunder {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        z-index: -1;

        img {
          height: 332px;
        }
      }

      .player-content {
        background-color: transparent;
        height: 100px;
        width: auto;
        aspect-ratio: 1 / 1;
        border-radius: 100px;
        margin-left: 0.5em;
        margin-right: 0.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .player {
          width: 100%;
        }
      }

      .vs {
        img {
          height: 3rem;
        }
      }
    }
  }

  .prizes {
    background: rgba(0, 0, 0, 0.08);
    border-radius: 32px 32px 0 0;
    width: 100%;
    bottom: 0;
    padding-top: 42px;
    margin-top: auto;

    .banner-position {
      margin-top: 42px;
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  #opponent {
    .searching-content {
      .war-background {
        margin-top: 24px;
        height: 110px;

        .war-thunder img {
          height: 300px;
        }

        .searching-opponent,
        .player-content {
          height: 84px;
        }
      }
    }

    .prizes {
      padding-top: 14px;

      .banner-position {
        margin-top: 14px;
      }
    }
  }
}
