@import "../../../mixins";

.hints-container {
  .hints-content {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .hint {
      display: flex;
      position: relative;

      button.hint-btn {
        font-weight: 900;
        font-size: 14px;
        line-height: 1;
        display: flex;
        align-items: center;
        width: auto;
        height: 34px;
        background-color: #fff;
        border-radius: 100px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
        border: 0;
        padding-left: 0;
        padding-right: 16px;
        color: #303030;

        &:disabled {
          color: #303030;
          opacity: .4;
          pointer-events: none;
        }

        img {
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          width: 25px;
          margin-top: -10px;
          margin-left: 8px;
          margin-right: 4px;
        }
      }
    }

    .slot-bottom {
      position: absolute;
      bottom: -17px;
      left: 50%;
      transform: translateX(-50%);

      .badge {
        width: auto;
        height: 24px;
        padding-left: 8px;
        padding-right: 8px;

        span {
          display: flex;
          align-items: center;
          @include shadow-text;
        }

        img {
          margin-right: 4px;
          width: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .hints-container {
    .hints-content {
      .hint {
        button.hint-btn {
          font-size: 12px;
          padding-right: 14px;
          height: 30px;

          img {
            width: 20px;
            margin-top: -8px;
          }
        }
      }
    }
  }
}
