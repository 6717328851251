@import "../../../mixins";

*,
*:after,
*:before {
  box-sizing: border-box;
}

.tabs-container {
  display: flex;
  // align-items: center;
  // justify-content: center;

  .tabs {
    display: flex;
    position: relative;
    background: rgba(0, 0, 0, 0.25);
    border: 2px solid #545454;
    border-radius: 16px;
    width: 100%;
    max-width: 640px;
    -webkit-tap-highlight-color: transparent; //Find alternative

    * {
      z-index: 2;
    }
  }

  input[type="radio"] {
    display: none;
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    flex: 1;
    font-style: normal;
    font-weight: 900;
    font-size: 17px;
    line-height: 23px;
    padding-bottom: 0;
    color: #FFFFFF;
    cursor: pointer;
    transition: color 0.15s ease-in;

    &.active {
      @include shadow-text;
    }
  }

  .notification {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin-left: 0.75rem;
    border-radius: 50%;
    border: 2px solid white;
    transition: 0.15s ease-in;
  }

  input[type="radio"] {
    &:checked {
      & + label {
        color: white;
        @include shadow-text;

        & > .notification {
          background-color: transparent;
          color: #fff;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  // input[id="radio-1"] {
  //     &:checked {
  //         &~.glider {
  //             transform: translateX(0);
  //         }
  //     }
  // }

  // input[id="radio-2"] {
  //     &:checked {
  //         &~.glider {
  //             transform: translateX(100%);
  //         }
  //     }
  // }

  .glider {
    position: absolute;
    display: flex;
    height: 52px;
    width: 50%;
    max-width: 318px;
    border-radius: 16px;
    z-index: 1;
    transition: 0.25s ease-out;

    &.glider-success {
      background: linear-gradient(184.36deg, #56D598 3.53%, #0E884E 96.46%);
      border: 2px solid #135736;
      box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);
    }

    &.glider-danger {
      background: linear-gradient(180deg, #FF9CAC 0%, #E7296D 100%);
      border: 2px solid #861C41;
      box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);
    }

    &.selected-offers {
      transform: translateX(0);
    }

    &.selected-coins {
      transform: translateX(100%);
    }
  }

}


// @media (max-width: 700px) {
//     .tabs {
//         transform: scale(0.6);
//     }
// }
