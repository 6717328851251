@import "../../../mixins";

.title-nav {
  margin-top: 8px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 50px auto 50px;
  align-items: center;
  width: 100%;

  .title {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 1.4;
    text-align: center;
    color: #fff;
    @include shadow-text(2px);

    &.small {
      font-size: 24px;
    }
  }

  .back-button {
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;

    a {
      text-decoration: none;
      color: #FFFFFF;
      display: flex;
      text-transform: uppercase;

      span {
        margin-left: 0.4em;
      }
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .title-nav {
    margin-top: 21px;
    margin-bottom: 16px;

    .title {
      font-size: 26px;
      line-height: 1.2;

      &.small {
        font-size: 24px;
      }
    }
  }
}
