.profile-settings-button {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  width: 100%;
  padding: 12px 16px;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 19px;
  color: white;
  border: none;
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr 24px;
  text-transform: uppercase;

  span {
    img {
      height: 24px;
      width: auto;
    }
  }

  .slot-left {
    height: 24px;
  }

  .slot-right {
    display: flex;
    flex-direction: row-reverse;
  }
}
