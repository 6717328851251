@import "../../../../mixins";

.ladder-message {
  .modal__content {
    background-color: transparent;
  }

  .message {
    position: relative;

    > * {
      position: relative;
      z-index: 1;
    }
  }

  .lights {
    position: absolute;
    top: 23%;
    bottom: 0;
    max-width: 395px;
  }

  .strikes {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    z-index: 5;
    bottom: 78%;
    max-width: 86%;
  }

  .player {
    width: 100%;
  }

  .player-container {
    width: 140px;
    margin-left: auto;
    margin-right: auto;
    -webkit-animation: floating 1s ease-in-out infinite;
    animation: floating 1s ease-in-out infinite;
  }

  .ribbon {
    position: relative;
    text-align: center;
    margin-top: -60px;

    img {
      max-width: 100%;
      filter: drop-shadow(0px 6px 0px rgba(0, 0, 0, 0.1));
    }

    p {
      font-weight: 900;
      font-size: 26px;
      color: #fff;
      @include shadow-text;
      text-transform: uppercase;
      position: absolute;
      top: 34%;
      left: 0;
      right: 0;
    }
  }

  &.safe-points {
    .ribbon {
      margin-top: -30px;

      p {
        font-size: 14px;
        top: 25%;
      }
    }
  }

  &.cushions {
   .modal__content {
     margin-left: 0;
     margin-right: 0;
   }

    .ribbon {
      max-width: 332px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;

      p {
        font-size: 14px;
        line-height: 19px;
        width: 152px;
        margin-left: auto;
        margin-right: auto;
        top: 5%;
      }
    }
  }

  .swimming-rings {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 64%;
    z-index: -1;
  }

  .reward-card {
    width: 200px;
    height: 80px;
    background: linear-gradient(180deg, #56D598 0%, #0E884E 100%);
    padding: 2px;
    box-shadow: 0 16px 32px rgb(0 0 0 / 35%);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 34px;

    > * {
      position: relative;
    }

    &::before {
      content: "";
      background: #fff;
      position: absolute;
      inset: 2px;
      border-radius: 16px;
      z-index: 0;
    }

    .points {
      font-weight: 900;
      font-size: 32px;
      text-align: center;
      margin-top: 4px;
      color: #fff;
      @include shadow-text;
    }

    .badge-container {
      position: absolute;
      top: 0;
    }

    img {
      position: absolute;
      filter: drop-shadow(0px 6px 0px rgba(0, 0, 0, 0.1));
      height: 40px;

      &:first-of-type {
        left: 0;
        transform: translateX(-50%);
      }

      &:nth-of-type(2) {
        right: 0;
        transform: translateX(50%);
      }
    }

    .star {
      &:first-of-type {
        transform: translateX(-50%) rotate(-17.78deg);
        top: 3px;
      }

      &:nth-of-type(2) {
        bottom: 6px;
        transform: translateX(50%) rotate(14.55deg);
      }
    }

    .diamond {
      &:first-of-type {
        top: 8px;
        transform: translateX(-50%) rotate(316deg);
      }

      &:nth-of-type(2) {
        bottom: 10px;
      }
    }
  }
}

@media (max-width: 360px) {
  .ladder-message {
    .ribbon {
      p {
        top: 32%;
      }
    }

    &.safe-points {
      .ribbon {
        p {
          top: 22%
        }
      }
    }

    &.cushions {
      .ribbon {
        p {
          top: 2%;
        }
      }
    }
  }
}
