.shop-swiper {
  padding: 24px;

  .swiper-pagination-bullets {
    margin-top: 24px;
  }
}

.shop-slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 25px;
  max-width: 600px;

  & > a {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }

  .shop-title {
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 38px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 1em;
    color: #FF3233;
  }

  .shop-ad {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    padding: 2em;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8477766106442577) 30%, rgba(0, 0, 0, 0) 100%);
    height: 300px;
    width: 100%;
    border-radius: 0 0 25px 25px;

    .sign-up-btn {
      width: 100%;

      .button-red-sign-up {
        background: #FF3233;
        border-radius: 0.5em;
        border: none;
        color: white;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 900;
        font-size: 17px;
        line-height: 23px;
        width: 100%;
        height: 2.7em;
      }
    }

    mark {
      color: red;
      font-size: 1.3em;
      background-color: transparent;
    }
  }

  .text-ad {
    color: white;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0.8em;
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .shop-swiper {
    padding: 16px;
  }
}
