@import "../../../mixins";

.player {
  width: 85%;
  height: 100% !important;
  aspect-ratio: 1 / 1;
  margin: auto;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  $border: 4px;
  color: #FFF;
  background-clip: padding-box;
  border: solid $border transparent;
  border-radius: 50%;

  &.has-shadow {
    box-shadow: 0 6px 0 rgba(0, 0, 0, 0.1);
  }

  > * {
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: -$border;
    border-radius: inherit;
  }

  &.player-primary:before {
    background: linear-gradient(180deg, #FFFFFF 0%, #D8D8D8 100%);
  }

  &.player-success:before {
    background: linear-gradient(184.36deg, #56D598 3.53%, #0E884E 96.46%);
  }

  &.player-defeat:before {
    background: linear-gradient(180deg, #FF9CAC 0%, #E7296D 100%);
  }

  &.winner {
    &::after {
      content: "";
      position: absolute;
      width: 130px;
      height: 130px;
      background-image: url('../../../../public/img/icns/rays.svg');
      -webkit-background-size: contain;
      background-size: contain;
      background-position: center;
      z-index: 0;
      -webkit-animation: rotating 7s linear infinite;
      animation: rotating 7s linear infinite;
    }
  }

  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-background-size: cover;
    background-size: cover;
  }

  .crown {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: absolute;
    bottom: 100%;
  }

  .name {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    @include shadow-text;
    position: absolute;
    top: 100%;
    margin-top: 30px;
  }

  .slot-bottom {
    width: 4em;
    height: 3em;
    position: absolute;
    bottom: -2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slot-left {
    width: 4em;
    height: 3em;
    position: absolute;
    left: -2.7em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slot-right {
    width: 4em;
    height: 3em;
    position: absolute;
    right: -2.7em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
