.area {
  position: relative;
  box-sizing: border-box;
  $border: 2px;
  background: #fff;
  background-clip: padding-box;
  border: solid $border transparent;
  border-radius: 1em;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border;
    border-radius: inherit;

  }

  .area-counter {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 8px 16px;
  }

  .icon-position {
    margin-right: -10px;
    margin-top: -10px;
    top: 0;
    right: 0;
    position: absolute;
  }

  .counter {
    font-weight: 900;
    font-size: 24px;
    background-color: red;
    background-size: 100%;
    background-repeat: repeat;
    background-clip: text;
    color: transparent;
  }

  .label {
    font-size: 10px;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: -4px;
  }

  &.diamonds {
    --points-area-background: linear-gradient(184.36deg, #9BD5FF 3.53%, #187FCB 96.46%);
  }

  &.coins {
    --points-area-background: linear-gradient(180deg, #FFD5A5 0%, #FF951D 100%);
  }

  &.lives {
    --points-area-background: linear-gradient(180deg, #ff9cac 0%, #e7296d 100%);
  }

  &.level {
    --points-area-background: linear-gradient(180deg, #d471ce 0%, #484ba6 100%);
  }

  &.strikes {
    --points-area-background: linear-gradient(180deg, #56d598 0%, #0e884e 100%);
  }

  &.ranking {
    --points-area-background: linear-gradient(180deg, #E9CDAA 0%, #D0A671 100%);
  }

  &.achievements {
    --points-area-background: linear-gradient(180deg, #FFC4E8 0%, #FF7298 100%);

  }

  &:before {
    background: var(--points-area-background);
  }

  .counter {
    background-image: var(--points-area-background);
  }

  img {
    max-width: 3.2em;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  &.area-secondary {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.area-primary {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column-reverse;
    text-align: center;

    .area-counter {
      display: flex;
      flex-direction: column-reverse;
      padding-left: 14px;
      padding-right: 14px;

      .label {
      }

      .counter {
        font-size: 28px;
        margin-top: 4px;
      }
    }

    .icon-position {
      margin-right: 0;
      position: relative;
      margin-top: -0.5rem;
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .area-primary {
    .area-counter {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .area {
    img {
      max-width: 40px;
    }
  }

  .area-secondary {
    .icon-position {
      margin-left: -20px;
    }

    .area-counter {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
