.language-container {
  .form-container {
    display: grid;
    gap: 24px;

    .form-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .language-grid {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        padding: 0 2em 0 2em;
        width: 100%;

        .input-aspect {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-top: 1em;
          padding-bottom: 1em;
          border-bottom: 1px solid #E9EBF0;

          &:first-child {
            border-top: 1px solid #E9EBF0;
          }

          .language {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            .flag {
              height: 50px;
              width: 50px;
              overflow: hidden;
              position: relative;
              border-radius: 50%;
              border: 2px solid #303030;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            label {
              font-size: 20px;
            }
          }
        }

        .check-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .check-container input[type='radio'] {
          position: absolute;
          z-index: 50;
          height: 34px;
          cursor: pointer;
          opacity: 0;
          width: 34px;
        }

        .check-container input[type='radio'] ~ span {
          cursor: pointer;
          width: 34px;
          height: 34px;
          display: inline-block;
          background-size: 34px 34px;
          background-repeat: no-repeat;
        }

        .checked-img {
          background-image: url('../../../../public/img/icns/radio-unchecked.png');
        }

        .check-container input[type='radio']:checked ~ span.checked-img {
          background-image: url('../../../../public/img/icns/radio-checked.png');
        }

      }

    }

    .title-language {
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      line-height: 24px;
      margin-top: 24px;
      margin-bottom: 24px;

    }

    label {
      padding-bottom: 0;
    }
  }
}

.button-submit-position {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
  background: #E9EBF0;
  border-radius: 0 0 16px 16px;
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .language-container {
    .form-container {
      .form-content {
        .language-grid {
          padding-left: 14px;
          padding-right: 14px;
        }
      }
    }
  }

  .button-submit-position {
    padding-left: 8px;
    padding-right: 8px;
  }
}
