.avatar {
    width: 40px !important;
    height: 40px !important;
    aspect-ratio: 1 / 1;
    margin: auto;
    position: relative;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    $border: 0.2em;
    color: #FFF;
    background-clip: padding-box;
    border: solid $border transparent;
    border-radius: 50%;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
    }

    &.avatar-primary:before {
        background: linear-gradient(180deg, #FFFFFF 0%, #D8D8D8 100%);
    }

    &.avatar-success:before {
        background: linear-gradient(184.36deg, #56D598 3.53%, #0E884E 96.46%);
    }

    &.avatar-defeat:before {
        background: linear-gradient(180deg, #FF9CAC 0%, #E7296D 100%);
    }

    .slot-bottom {
        width: 4em;
        height: 3em;
        position: absolute;
        bottom: -2em;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slot-left {

        width: 4em;
        height: 3em;
        position: absolute;
        left: -1.5em;
        top: -1.6em;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slot-right {

        width: 4em;
        height: 3em;
        position: absolute;
        right: -1.5em;
        top: -1.6em;

        display: flex;
        align-items: center;
        justify-content: center;
    }


}
