#store {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.tab-area {
  padding-left: 24px;
  padding-right: 24px;
}

@media screen and (max-width: 360px),
screen and (max-height: 680px)  {
  .tab-area {
    padding-left: 16px;
    padding-right: 16px;
  }
}
