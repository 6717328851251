.badges-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .badges-content {
    padding: 0 24px 24px;
  }

  .achievement-container {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.35);
    display: grid;
    grid-template-columns: repeat(2 ,minmax(0,1fr));
    gap: 24px;
    padding: 24px;
    flex-wrap: wrap;
    margin-top: 16px;
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .badges-container {
    .badges-content {
      padding-left: 16px;
      padding-right: 16px;
    }

    .achievement-container {
      margin-top: 0;
      gap: 16px;
      padding: 16px;
    }
  }
}
