.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 24px 24px;
  position: relative;

  .icon-position {
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.35));
    margin-top: -2em;
    position: absolute;
  }

  .card-content {
    width: 100%;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.35);
    border-radius: 1em;
    padding: 24px;
    padding-top: 40px;
    text-align: center;
  }

  .text {
    h3 {
      font-weight: 900;
      font-size: 18px;
      line-height: 1.25;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    p {
      max-width: 151px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 26px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .store-offers {
    display: grid;
    gap: 24px;
    align-items: start;
    justify-items: center;

    .store-offer {
      width: 100%;
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .card-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
