@import "../../../mixins";

.badge-rewards {
  background: linear-gradient(90.1deg, #DA6E01 -2.36%, #FFCB66 23.61%, #F2A201 54.49%, #FFD53D 75.8%, #F4AA01 85.14%, #F09E01 94.09%, #ED9601 98.93%, #A66730 102.12%);
  border-radius: 100px;
  padding: 2px 16px;
  font-weight: 900;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  display: inline-block;
  @include shadow-text;
  transform: translateY(-50%);
}
