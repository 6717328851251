.subscribe-page {
  .cost {
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;
  }

  .cost-free {
    font-weight: 700;
    font-size: 14px;
    margin-top: 0;
  }

  .terms-area {
    margin-top: 13px;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 360px), screen and (max-height: 680px) {
  .subscribe-page .auth-content .auth-shell-content .terms-area {
    margin-top: 13px;
  }
}
