@import "../../../mixins";

#category {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .category-content {
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 26px;
  }

  .content {
    height: 100%;
  }

  #prizes {
    margin-top: -103px;
    padding-top: 113px;
    padding-bottom: 16px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 32px 32px;
  }

  #peel {
    position: relative;
    width: 100%;
    flex: 1;
    //margin-top: 26px;

    .peel-circle {
      position: relative;
      background: linear-gradient(
        to top right,
        yellow 60%,
        orange 75%,
        white 75%,
        white 100%
      );
      border-radius: 100%;
      border: 4px solid #ffffff;
      box-sizing: content-box;
      box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25);

      &:before {
        content: "";
        position: absolute;
        top: -50%;
        left: 50%;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          to bottom left,
          transparent 75%,
          yellow 75%,
          yellow 100%
        );
        border-radius: 100%;
      }
    }

    .peel-circle-purple {
      position: absolute;
      left: 5%;
      top: 1%;
      padding-bottom: 61.334%;
      width: 61.334%;
      background: linear-gradient(
        to top right,
        #c368bd 20.45%,
        #ce6ec8 37.39%,
        #c469be 75%,
        white 75%,
        white 100%
      );

      &:before {
        background: linear-gradient(
          to bottom left,
          transparent 75%,
          #e79ae2 75%,
          #e79ae2 100%
        );
      }
    }

    .peel-circle-orange {
      position: absolute;
      left: 40%;
      top: 0;
      padding-bottom: 53.3345%;
      width: 53.3345%;
      background: linear-gradient(
        to top right,
        #f49a34 50%,
        #c47100 75%,
        white 75%,
        white 100%
      );

      &:before {
        background: linear-gradient(
          to bottom left,
          transparent 75%,
          #f0b672 75%,
          #f0b672 100%
        );
      }
    }

    .peel-circle-pink {
      position: absolute;
      left: 30%;
      top: 8.4%;
      padding-bottom: 64%;
      width: 64%;
      background: linear-gradient(
        to top right,
        #e25f8e 50%,
        #bb2e64 75%,
        white 75%,
        white 100%
      );

      &:before {
        background: linear-gradient(
          to bottom left,
          transparent 75%,
          #ec86aa 75%,
          #ec86aa 100%
        );
      }
    }

    .peel-circle-aqua {
      position: absolute;
      left: 5%;
      top: 20%;
      padding-bottom: 48%;
      width: 48%;
      background: linear-gradient(
        to top right,
        #5cb1ae 50%,
        #5a9996 75%,
        white 75%,
        white 100%
      );

      &:before {
        background: linear-gradient(
          to bottom left,
          transparent 75%,
          #5cb1ae 75%,
          #5cb1ae 100%
        );
      }
    }

    .peel-circle-orange-dark {
      position: absolute;
      left: 33%;
      top: 30%;
      padding-bottom: 48%;
      width: 48%;
      background: linear-gradient(
        to top right,
        #d4a165 50%,
        #c47100 75%,
        white 75%,
        white 100%
      );

      &:before {
        background: linear-gradient(
          to bottom left,
          transparent 75%,
          #d4a165 75%,
          #d4a165 100%
        );
      }
    }

    .peel-circle-blue {
      position: absolute;
      left: 9%;
      top: 24%;
      padding-bottom: 61.335%;
      width: 61.335%;
      background: linear-gradient(
        to top right,
        #54abeb 50%,
        #54abeb 75%,
        white 75%,
        white 100%
      );

      &:before {
        background: linear-gradient(
          to bottom left,
          transparent 75%,
          #54abeb 75%,
          #54abeb 100%
        );
      }
    }

    .peel-circle-purple-2 {
      position: absolute;
      left: 23%;
      top: 23%;
      padding-bottom: 66.67%;
      width: 66.67%;
      background: linear-gradient(
        to top right,
        #c368bd 20.45%,
        #ce6ec8 37.39%,
        #c469be 75%,
        white 75%,
        white 100%
      );

      &:before {
        background: linear-gradient(
          to bottom left,
          transparent 75%,
          #e79ae2 75%,
          #e79ae2 100%
        );
      }
    }

    .peel-circle-indigo {
      position: absolute;
      left: 12%;
      top: 38%;
      padding-bottom: 48%;
      width: 48%;
      background: linear-gradient(
        to top right,
        #8b8ed3 20.45%,
        #8b8ed3 37.39%,
        #8b8ed3 75%,
        white 75%,
        white 100%
      );

      &:before {
        background: linear-gradient(
          to bottom left,
          transparent 75%,
          #8b8ed3 75%,
          #8b8ed3 100%
        );
      }
    }

    .peel-circle-indigo-2 {
      position: absolute;
      left: 37%;
      top: 33%;
      padding-bottom: 53.3345%;
      width: 53.3345%;
      background: linear-gradient(
        to top right,
        #8b8ed3 20.45%,
        #8b8ed3 37.39%,
        #8b8ed3 75%,
        white 75%,
        white 100%
      );

      &:before {
        background: linear-gradient(
          to bottom left,
          transparent 75%,
          #8b8ed3 75%,
          #8b8ed3 100%
        );
      }
    }
  }
}

.peel-circle-wrapper {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  width: 280px;
  height: 280px;
  margin: auto;

  .hand-peel {
    position: absolute;
    z-index: 2;
    right: 4%;
    top: 18%;
    pointer-events: none;
  }
}

.peel-circle-special {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 4px solid #ffffff;
  box-sizing: content-box;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;

  .peel-to-play {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 1;

    h3 {
      font-weight: 900;
      font-size: 34px;
      line-height: 34px;
      color: #FFFFFF;
      @include shadow-text;
    }
  }

  .circle-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .circle-content-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-45deg);
  }

  .circle-content {
    min-height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    .icon-category {
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: auto;
      max-width: 50px;
      margin-top: 6%;
    }

    h3 {
      margin-top: auto;
      margin-bottom: 5px;
      font-weight: 900;
      font-size: 34px;
      line-height: 34px;
      color: #303030;
    }
  }

  .circle-bg-wrapper {
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .circle-bg {
      position: absolute;
      bottom: 0;
      height: 280px;
      width: 280px;
      background: linear-gradient(to top, #56d598 100%, #56d598 100%);
      border-radius: 100%;
    }
  }

  .circle-top {
    position: relative;
    left: 0;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    touch-action: none;
    z-index: 2;

    .circle {
      position: absolute;
      background: #74e1ad;
      border-radius: 100%;
    }
  }
}


/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  border-radius: 5px;
  background-color: #303030;
  color: #303030;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #303030;
  color: #303030;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #303030;
  color: #303030;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #303030;
  }
  50%,
  100% {
    background-color: #d6d6d6;
  }
}

@media (max-width: 360px),
screen and (max-height: 680px) {
  #category {
    .category-content {
      gap: 16px;
    }
  }
}
