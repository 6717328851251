@import "../../../mixins";

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  border-color: #fff;
  border-width: 2px;
  border-style: solid;
  color: #fff;
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 18px;

  span {
    @include shadow-text;
  }

  img {
    width: 20px;
    text-shadow: none;
    filter: none;
  }

  &.badge-circle {
    width: 24px;
    height: 24px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;

    span {
      filter: none;
      text-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 90%;
    }
  }

  &.badge-lost {
    background: #F01111;
  }

  &.badge-win {
    background: #1B8652;
  }

  &.badge-rectangle {
    width: 34px;
    height: 34px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.35);
    border-radius: 1em;
  }

  &.badge-primary {
    background: #9D6AEF;
  }

  &.badge-success {
    background: linear-gradient(184.36deg, #56D598 3.53%, #0E884E 96.46%);
  }

  &.badge-danger {
    background: linear-gradient(180deg, #FF9CAC 0%, #E7296D 100%);
  }
}
