.edit-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 100%;
  overflow: auto;

  .profile-content {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 88px;
  }

  form {
    width: 100%;
  }

  .edit-profile-cards {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-content: center;
    width: 100%;

    .card {
      padding: 16px 24px 24px;
      background-color: white;
      border-radius: 1em;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.35);
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .card-margin-last {
      padding-bottom: 2em;
    }
  }

  .button-position {
    bottom: 16px;
    left: 0;
    right: 0;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
  }

  .input-wrapper {
    width: 100%;

    input {
      width: 100%;
    }
  }
}

.avatar-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: center;
  justify-items: center;
  align-content: center;
  grid-template-rows: 8em;
  margin-top: 1em;
}

.upload-picture {
  max-width: 400px;
}

label {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  padding-bottom: 8px;
  text-align: center;
}

label.avatars {
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatars > input {
  display: none;
  padding-bottom: 0;
}

.avatars > input + img {
  cursor: pointer;
  border-radius: 50%;
  height: 7em;
  width: 7em;
  border: white solid 3px;
  -webkit-tap-highlight-color: transparent;
}

.avatars > input:checked + img {
  box-shadow: #56D598 0 0 0px 5px;
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .edit-profile {
    .profile-content {
      padding-left: 16px;
      padding-right: 16px;
    }

    .button-position {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
