#prizes {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'nunito', sans-serif;

    .prize {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      //flex: 1;

        .prize-image {
            //width: 8em;
            height: 6em;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .prize-name {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 900;
            font-size: 12px;
            color: #fff;
            text-align: center;
            text-shadow: 0px 1.72923px 0px rgba(0, 0, 0, 0.5);
            text-transform: uppercase;
            padding-top: 0.3em;
        }
    }

    .prize-title {
        font-weight: 900;
        font-size: 26px;
      padding-left: 6px;
      padding-right: 6px;
        color: #fff;
        text-align: center;
        text-shadow: 0px 1.72923px 0px rgba(0, 0, 0, 0.5);

        .small-title {
            font-size: 18px;
            margin-top: -5px;
        }
    }

}

@media (max-width: 690px),
screen and (max-height: 680px) {
  #prizes {
    .prize {
      .prize-image {
        height: 3.5em;
      }
    }

    .prize-title {
      font-size: 24px;
    }
  }
}
