.accordion-item {
  margin-bottom: 1em;
  border-radius: 1em;
  background: linear-gradient(180deg, #FFFFFF 0%, #D8D8D8 100%);
  padding: 4px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.35);
  position: relative;

  > * {
    position: relative;
  }

  &::before {
    content: "";
    background: #fff;
    position: absolute;
    inset: 4px;
    border-radius: 16px;
    z-index: 0;
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  &.active {
    .accordion-item-btn::after {
      background-image: url('../../../../public/img/icns/dropdown-after.png');
    }
  }
}

.accordion-item-title {
  width: 100%;
  margin: 0;
}

.accordion-item-btn {
  display: flex;
  align-items: flex-start;
  width: 100%;
  border: 0;
  padding: 16px 16px 16px 24px;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  cursor: pointer;
  background-color: transparent;
  border-radius: 16px;
  gap: 8px;
  color: #303030;

  &::after {
    content: '';
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-left: auto;
    background-image: url('../../../../public/img/icns/dropdown-initial.png');
    background-repeat: no-repeat;
    background-size: 100%;
    transition: transform 0.5s ease-in-out;
  }
}

.accordion-item-container {
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
  max-height: 0;

  &.active {
    height: auto;
    max-height: 500px;
  }
}

.accordion-item-content {
  padding: 0 24px 24px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .accordion-item-btn {
    font-size: 16px;
    line-height: 22px;
  }

  .accordion-item-content {
    font-size: 14px;
    line-height: 19px;
  }
}
