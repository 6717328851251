.leaderboard-container {
  height: calc(100vh - 177px);
  overflow: auto;

    ul {
        list-style-type: none;
        margin-block-start: 0em;
        margin-block-end: 0em;
        padding: 0;
    }

    .leaderboard-scrolling {

    }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .leaderboard-container {
    height: calc(100vh - 172px);
  }
}
