.prize-question-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;

    img {
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    }

    #category {
        margin-right: 0.3em;
      max-height: 48px;
      max-width: 48px;
    }

    #prize {
        margin-right: 0.3em;
        margin-left: 0.3em;
    }

    span {
        color: #458BDF;
    }
}
