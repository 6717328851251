@import "../../../../mixins";

.gameplay-text {
  height: 54px;
  background: #f4f4f4;
  border: 2px solid #9f9f9f;
  box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding-right: 0;
  padding-left: 0;
  transition: all 0.3s ease-out;
  color: #303030;

  &.correct {
    background: linear-gradient(184.36deg, #56d598 3.53%, #0e884e 96.46%);
    border: 2px solid #135736;
    box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
  }

  &.wrong {
    background: linear-gradient(180deg, #ff9cac 0%, #e7296d 100%);
    border: 2px solid #861c41;
    box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
  }

  &.correct,
  &.wrong {
    .overlay-input {
      background-color: rgba(255, 255, 255, 0.25);
    }

    .slot-text {
      color: #fff;
      @include shadow-text;
    }
  }
}

.overlay-input {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 36px auto 36px;
  background-color: #fff;
  height: 25px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 1em;
  margin-top: -25px;
  position: relative;

  &.outer {
    display: block;
    margin-left: auto;
    margin-right: auto;
    --slot-size: 36px;
    --icon-size: 34px;

    .slot-start,
    .slot-end {
      position: absolute;
      top: 0;
      width: var(--icon-size);

      img {
        height: var(--icon-size);
      }
    }

    .slot-start {
      left: calc(var(--slot-size) * -1);

      &.opponent {
        left: auto;
        right: calc(var(--slot-size) * -1);
      }
    }

    .slot-end {
      right: calc(var(--slot-size) * -1);

      &.user {
        right: auto;
        left: calc(var(--slot-size) * -1);
      }
    }

    .slot-text {
      justify-content: center;
    }
  }
}

.answer-grid-input {
  width: 100%;
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
  margin-top: -16px;

  .slot {
    display: flex;
    align-items: center;
    line-height: 1.1;
    height: 50px;
  }

  .slot-end {
    justify-content: flex-end;
  }
}

.icon-input {
  height: 23px;
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .overlay-input.outer {
    --slot-size: 30px;
    --icon-size: 28px;
  }

  .answer-grid-input {
    font-size: 18px;
  }
}
