#unsubscribe {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .stream-service {
    margin-top: auto;
    padding-top: 24px;
  }

  p {
    margin: 0;
  }

  .subscription-info-item {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E9EBF0;
    padding: 16px 0;

    p {
      text-align: left;
      padding-right: 10px;
    }

    .tag-item {
      background: #E9EBF0;
      border-radius: 100px;
      padding: 4px 8px;
      text-transform: capitalize;
    }

    &:first-of-type {
      border-top: 1px solid #E9EBF0;
    }
  }

  .btn-container {
    margin-top: 25px;
  }

  .modal-content {
    .modal-description {
      padding: 24px;
      text-align: center;
      line-height: 1.4;

      .title-modal {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 800;
      }

      .description {
        padding: 0 24px;

        img {
          margin-bottom: 16px;
        }

        p {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }

    .bottom-area {
      padding-bottom: 24px;
      gap: 24px;
    }
  }
}


@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  #unsubscribe {
    .modal-content  {
      .modal-description {
        padding: 16px;

        .description {
          padding: 0 8px;
        }
      }

      .bottom-area {
        padding-bottom: 16px;
      }
    }
  }
}
