@import "../../../mixins";

.badge-card {
  background: linear-gradient(180deg, #FBFAFF 0%, #C6BBFF 100%);
  border: 2px solid #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;

  &.unlocked {
  }

  &.locked {
    background: linear-gradient(180deg, rgba(251, 250, 255, .5) 0%, rgba(198, 187, 255, .5) 100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);

    > * {
      opacity: .5;
    }

    > img {
      margin-top: 4px;
      opacity: 1;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.35);
      border-radius: 10px;
    }
  }

  .badge-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .badge-title {
    font-style: normal;
    font-weight: 800;
    font-size: 1em;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 8px;
    text-align: center;
    @include shadow-text;
  }

  .badge-subtitle {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    background: #B5A7FF;
    border-radius: 100px;
    padding: 4px 8px;
    margin-top: 8px;
  }
}
