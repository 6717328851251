.button-shop-container {
  display: flex;
  align-items: center;
  border-radius: 100rem;
  font-size: 1rem;
  padding: 4px 0.8rem 3px;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #D471CE, #484BA6);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  position: relative;

  .button-text-area {
    background-image: linear-gradient(180deg, #D471CE 0%, #484BA6 100%);
    background-clip: text;
    color: transparent;
    padding-left: 3em;
    text-align: initial;

    .button-title {
      font-weight: 900;
      font-size: 18px;
      line-height: 1;
    }

    .button-subtitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
    }
  }

  .shop-icon {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: absolute;
    margin-left: -1.3em;
    margin-top: -15px;

    img {
      width: 60px;
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .button-shop-container {
    position: relative;
    border-width: 2px;

    .shop-icon {
      margin: 0;
      top: -15px;
      left: -17px;

      img {
        width: 34px;
      }
    }

    .button-text-area {
      padding-left: 10px;

      .button-title {
        font-size: 11px;
        line-height: 11px;
      }

      .button-subtitle {
        font-size: 9px;
        line-height: 10px;
      }
    }
  }
}
