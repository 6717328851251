@import "../../../mixins";

//.modal.active {
//  align-items: unset;
//  justify-content: unset;
//}
//
//.modal__content {
//  width: 100%;
//  margin: 0;
//  max-width: 100%;
//}

.background-modal-category {
  max-width: 100%;
}

.overlay-background {
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
}

.green-lights {
  background: url('../../../../public/img/bonus/backgroud-lights.png');
  height: 90vh;
  width: inherit;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.bonus-container {
  background-color: white;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.35);
  width: 90%;
  max-width: 327px;
  min-height: 275px;
  border-radius: 40%/35%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  z-index: 1;

  .bonus-emoji {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    position: relative;
    // left: 50%;
    // transform: translateX(-50%);
    margin-top: -50px
  }

  .bonus-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .bonus-subtitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      margin-top: 20px;
    }

    .bonus-title {
      font-weight: 900;
      font-size: 44px;
      line-height: 44px;
      background: linear-gradient(180deg, #FFB0A6 0%, #C40202 100%);
      margin-top: 8px;
      margin-bottom: 16px;
      background-clip: text;
      color: transparent
    }

    .bonus-award {
      min-width: 120px;
      height: 60px;
      background: linear-gradient(180deg, #FAD43B 0%, #F8990D 100%);
      border-radius: 16px;
      margin-bottom: 11px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .bonus-award-container {
        margin-left: auto;
        margin-right: auto;
        padding-left: 7px;
        padding-right: 7px;

        .bonus-award-quantity {
          color: #FFFFFF;
          @include shadow-text;
          font-weight: 900;
          font-size: 24px;
          line-height: 33px;

          img {
            max-width: 40px;
          }
        }

        .bonus-award-content {
          font-weight: 900;
          font-size: 8px;
          line-height: 11px;
          color: #303030;
          text-transform: uppercase;
        }
      }

      .slot-icons {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        height: 30px;
      }
    }

    .bonus-description {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px;
      margin-top: 5px;

      span {
        font-weight: 800;
      }
    }
  }

  .bonus-button {
    max-width: 247px;
    width: 100%;
  }
}
