.gameplay-image {
    height: 180px;
    width: 140px;
    border: #CBCED7 solid 2px;
    box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    background: none;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  &.correct {
    box-shadow: inset 0px -6px 0px #B5CCC1;
    background:
    linear-gradient(#fff, #fff) padding-box, linear-gradient(180deg, #56D598 3.53%, #135736 96.46%) border-box;
    border: 2px solid transparent;
  }

  &.wrong {
    box-shadow: inset 0px -6px 0px #DFC5CD;
    background:
    linear-gradient(#fff, #fff) padding-box, linear-gradient(180deg,#FC739C 0%, #861C41 100%) border-box;
    border: 2px solid transparent;
  }

  .image-area {
    height: 158px;
    width: 124px;
    border-radius: 8px;
    overflow: clip;
    margin-top: -6px;

    img{
      height: 158px;
      width: 124px;
      object-fit: cover;
    }
  }

  .answer-badge {
      position: absolute;
      margin-top: -90px;
      z-index: 2;
    }
}
