.searching-opponent {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #FFFFFF 0%, #D8D8D8 100%);
  border-radius: 50%;
  height: 100px;
  width: auto;
  aspect-ratio: 1 / 1;
  margin-left: 0.5em;
  margin-right: 0.5em;;

  .spinner {
    position: absolute;
    width: 101px;
    height: 101px;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #7459FF 94%, rgba(0, 0, 0, 0)) top/5.1px 5.1px no-repeat, conic-gradient(rgba(0, 0, 0, 0) 30%, #7459FF);
    mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 5.1px), #000 0);
    animation: spinner-opponent 1.4s infinite linear;

  }

  @keyframes spinner-opponent {
    100% {
      transform: rotate(1turn);
    }
  }

  img {
    height: 3em;
    width: auto;
  }
}
