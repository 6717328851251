@import "../../../mixins";

.main-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: calc(100vh - 60px);
  height: 100%;
  overflow: auto;

  .profile-content {
    padding-left: 24px;
    padding-right: 24px;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile-image-area {
      background-image: url('../../../../public/img/profile-stars.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
      width: 16em;
      //height: 9em;
      display: flex;
      flex-direction: column;
      align-items: center;

      .profile-image {
        height: 120px;
        width: 120px;
      }

      .profile-camera {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -4em;
        z-index: 2;
        margin-right: -6em;
        background-color: #FFFFFF;
        border-radius: 0.7em;
        border-style: none;
        width: 2.2em;
        height: 2.2em;
        padding: 1.2em;
        position: relative;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.35);

        // .icon-camera {
        //     background-image: url(../../../../public/img/icns/camera.png);
        //     background-position: right;
        //     display: inline;
        //     height: 20px;
        // }
      }
    }

    .player-name {
      color: #FFFFFF;
      @include shadow-text;

      h2 {
        font-weight: 900;
        font-size: 24px;
        line-height: 33px;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }

  }

  .points {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 24px;
    width: 100%;
    margin-top: 24px;
  }

  .settings-buttons {
    width: 100%;
    display: grid;
    gap: 8px;
    margin-top: 24px;
  }

  .store-button {
    padding-top: 2.3em;
    padding-bottom: 2.3em;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .main-profile .profile-content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
