.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  .loader {
    border: 16px solid rgba(255, 255, 255, .3);
    border-top: 16px solid #fff;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
