@import "../../mixins";

#results {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  overflow: auto;

  .content-results {
    padding: 8px 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title-results {
    position: relative;
    display: flex;
    justify-content: center;
    filter: drop-shadow(0px 6px 0px rgba(0, 0, 0, 0.1));
    margin-bottom: 20px;

    img {
      max-width: 100%;
    }

    p {
      font-weight: 900;
      font-size: 14px;
      line-height: 19px;
      color: #fff;
      position: absolute;
      top: 25%;
      @include shadow-text;
    }
  }

  .players {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 216px;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 100px;
    padding: 8px;
  }

  .player-wrapper {
    width: 70px;

    .player {
      width: 100%;
    }
  }

  .title-rewards {
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: #fff;
    @include shadow-text;
    margin-top: 60px;
  }

  .rewards {
    margin-top: 24px;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(216,216,216,1) 100%);
    padding: 4px;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.35);
    border-radius: 16px;
    min-width: 180px;
    position: relative;

    &.multiple {
      width: 100%;
    }

    > * {
      position: relative;
    }

    &::before {
      content: "";
      background: #fff;
      position: absolute;
      inset: 4px;
      border-radius: 16px;
      z-index: 0;
    }
  }

  .list-rewards {
    display: inline-flex;
    width: 100%;
    padding: 14px 14px 18px;
    flex-wrap: wrap;
    justify-content: center;

    .item {
      padding: 5px;
      width: 33.33%;

      .img {
        position: relative;
        display: inline-flex;
      }

      img {
        height: 38px;
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
        margin-bottom: 10px;
      }

      p {
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        color: #fff;
        @include shadow-text;
      }

      .information {
        position: absolute;
        top: 0;
        left: 100%;

        img {
          filter: none;
          width: 24px;
        }
      }
    }
  }

  .btn-container {
    margin-top: 20px;
    width: 100%;

    p {
      font-weight: 800;
      font-size: 18px;
      line-height: 1.4;
      color: #fff;
      @include shadow-text;
      margin-top: 15px;
    }
  }

  .prizes-win {
    margin-top: auto;
    background: rgba(0, 0, 0, 0.12);
    padding: 12px;
    border-radius: 32px 32px 0 0;
  }

  .modal-buy-coins {
    .card-container {
      padding: 0;
    }
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  #results {
    .list-rewards {
      padding-left: 5px;
      padding-right: 5px;
    }

    .btn-container {
      p {
        font-size: 16px;
      }
    }
  }
}
