.modal-content {
    .top-area {
        display: flex;
        align-items: center;
        padding: 1.5em;

        .message-icon {
            img {
                filter: drop-shadow(0px 8px 16px #00000059);

            }
        }

        .message {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 800;
            padding-left: 1em;

            .text {
                font-size: 14px;
                line-height: 19px;
            }

            .phone {
                font-size: 22px;
                line-height: 27px;
                padding-top: 0.4em;
            }
        }
    }

    .bottom-area {
        padding-top: 1.5em;
        padding-right: 2em;
        padding-bottom: 2em;
        padding-left: 2em;
        background-color: gainsboro;
        border-radius: 0em 0em 1em 1em;
    }
}

@media screen and (max-height: 750px) {
    .modal-content {
        .top-area {
            zoom: 85%;
        }

        .bottom-area {
            zoom: 85%;
        }
    }
}