.achievement-modal-container {
  display: grid;
  grid-template-rows: 200px 1fr;
  justify-items: center;

  .badge-header {
    background-image: url('../../../../public/img/achievement-modal.png');
    background-position: center center;
    background-size: cover;
    clip-path: ellipse(100% 100% at top center);
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 1em 1em 0px 0px;

    img {
      margin-top: -2em;
      filter: drop-shadow(0px 6px 0px rgba(0, 0, 0, 0.1));
    }
  }

  .badge-content {
    display: flex;
    gap: 1em;
    flex-direction: column;
    align-items: center;
    padding: 16px 16px 24px 16px;
    width: 100%;

    .badge-title {
      font-weight: 900;
      font-size: 44px;
      line-height: 1.1;
      text-align: center;
    }

    .badge-text {
      font-weight: 600;
      font-size: 1em;
      line-height: 1.1em;
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    .share-area {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      row-gap: 24px;

      button.share-facebook {
        font-weight: 600;
        font-size: 0.9em;
        line-height: 1.1em;
        display: grid;
        align-items: center;
        grid-template-columns: 24px 1fr;
        background: linear-gradient(180deg, #17A5FE 0%, #0058DB 100%);
        border-radius: 100px;
        border: none;
        color: white;
        padding: 8px 16px 8px 8px;
        gap: 6px;
      }
    }
  }
}
