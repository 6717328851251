.prize-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  background-color: white;
  border-radius: 1em;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);

  .prize-card-header {
    height: 100px;
    display: contents;

    .prize-card-header-background {
      background: linear-gradient(180deg, #FFD5A5 0%, #fead51 64.62%);
      width: 100%;
      border-radius: 1em 1em 0em 0em;
      clip-path: ellipse(100% 100% at top center);
      height: 100px;

      .prize-stars {
        background: url('../../../../public/img/prize/stars.png');
        background-size: cover;
        width: 100%;
        height: 100%;
      }
    }


    img {
      z-index: 1;
      margin-top: -6em;
    }
  }

  .prize-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .prize-title {
      font-weight: 900;
      font-size: 40px;
      line-height: 36px;
      color: #FFA035;
    }

    .prize-subtitle {
      font-weight: 900;
      font-size: 24px;
      line-height: 33px;
      padding-bottom: 1em;
    }
  }
}

.prize-card-container-small {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  height: 100px;
  background-color: white;
  background-image: url('../../../../public/img/prize/stars-gray.svg');
  background-repeat: no-repeat;
  background-position: left 5px center;
  -webkit-background-size: contain;
  background-size: contain;
  border-radius: 1em;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.25);

  .prize-left-slot {
    display: flex;
    flex-direction: column;
    padding-left: 13px;
    flex: 1;

    .prize-title-small {
      font-weight: 900;
      font-size: 18px;
      line-height: 1.2;
      color: #FFA035;
    }

    .prize-subtitle-small {
      font-weight: 900;
      font-size: 20px;
      line-height: 1.2;
      margin-top: -2px;
    }
  }

  .prize-right-slot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    height: 100px;
    max-width: 43%;

    .background-right {
      position: absolute;
      display: block;
      border-radius: 1em;
      width: 31.5%;
      max-width: 150px;
      height: 100px;
      clip-path: ellipse(100% 100% at right center);
      background: linear-gradient(180deg, #FFD5A5 0%, #fead51 64.62%);
    }

    .prize-stars-sm {
      background: url('../../../../public/img/prize/stars-sm.png');
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center right;
    }

    img {
      z-index: 1;
      margin-right: 13px;
      max-width: 100%;
      max-height: 118px;
      height: auto;
      filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.25));
    }
  }
}
