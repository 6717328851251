.stats-chip {
  display: flex;
  align-items: center;
  height: 24px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  padding-left: 4px;
  padding-right: 10px;
  position: relative;

  &::before {
    content: "";
    background: #fff;
    position: absolute;
    inset: 2px;
    border-radius: 100px;
    z-index: 0;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  img, svg {
    height: 30px;
    margin-right: 8px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .info {
    p {
      font-weight: 900;
      font-size: 16px;
      line-height: 1;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      &.level {
        background-image: linear-gradient(180deg, #D471CE 0%, #484BA6 100%);
      }

      &.points {
        background-image: linear-gradient(184.36deg, #9BD5FF 3.53%, #187FCB 96.46%);
      }

      &.coins {
        background-image: linear-gradient(180deg, #FFD5A5 0%, #FF951D 100%);
      }

      &.lives {
        background-image: linear-gradient(180deg, #FF9CAC 0%, #E7296D 100%);
      }
    }

    span {
      font-weight: 900;
      font-size: 10px;
      line-height: 1;
      text-transform: uppercase;
      display: block;
    }
  }

  &.chip-level {
    background: linear-gradient(180deg, #d471ce 0%, #484ba6 100%);
    height: 46px;
    padding-left: 8px;
    padding-right: 8px;

    img, svg {
      margin-right: 4px;
    }
  }

  &.chip-points {
    background: linear-gradient(180deg, #9BD5FF 0%, #187FCB 100%);
  }

  &.chip-coins {
    background: linear-gradient(180deg, #FFD5A5 0%, #FF951D 100%);
  }

  &.chip-lives {
    background: linear-gradient(180deg, #FF9CAC 0%, #E7296D 100%);
  }

  &.chip-0 {
    //display: none;
  }
}

@media (max-width: 360px) {
  .stats-chip {
    height: 20px;
    padding-right: 9px;

    img, svg {
      height: 25px;
    }

    .info {
      p {
        font-size: 14px;

        &.level {
          font-size: 16px;
        }
      }

      span {
        font-size: 8px;
      }
    }

    &.chip-level {
      height: 40px;
      padding-left: 7px;
      padding-right: 7px;
    }
  }
}
