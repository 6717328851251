.modal-content {
  .top-area {
    display: flex;
    align-items: center;
    padding: 24px 27px;

    .message-icon {
      img {
        width: 50px;
        filter: drop-shadow(0px 8px 16px #00000059);

      }
    }

    .message {
      font-style: normal;
      font-weight: 800;
      padding-left: 1em;
      text-align: left;

      .text {
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
      }

      .text-big {
        font-weight: 900;
        font-size: 22px;
        line-height: 27px;
      }

      .phone {
        font-weight: 900;
        font-size: 22px;
        line-height: 27px;
        padding-top: 4px;
      }
    }
  }

  .bottom-area {
    padding: 24px 32px;
    background: #E9EBF0;
    border-radius: 0 0 1em 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 15px;
  }
}

@media screen and (max-width: 360px),
screen and (max-height: 680px) {
  .modal-content {
    .top-area {
      padding: 16px 16px;

      .message {
        .phone {
          font-size: 18px;
        }

        .text-big {
          font-size: 18px;
        }
      }
    }

    .bottom-area {
      padding: 16px;
    }
  }
}
